import { Injectable, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  CaseManagementUserDto,
  UserDepartmentDto,
  UserInfoDto
} from 'api/models';
import { Observable, merge, zip, BehaviorSubject } from 'rxjs';

// TODO: Rename to ContentService since it now handles more thant just the title
@Injectable({
  providedIn: 'root'
})
export class ContentTitleService {
  pageTitleChanged: Observable<string>;
  companyIdChanged: EventEmitter<string>;
  companyId: string;
  mainDepartment: UserDepartmentDto;
  mainDepartmentChanged: EventEmitter<UserDepartmentDto>;
  departments: UserDepartmentDto[] = [];
  departmentsChanged: EventEmitter<UserDepartmentDto[]>;
  userIdChanged: EventEmitter<string>;
  userId: string;
  userInfoChanged: EventEmitter<UserInfoDto>;
  userInfo: UserInfoDto;

  pageTitle$ = new BehaviorSubject('');
  constructor(
    private title: Title,
    private readonly translate: TranslateService
  ) {
    this.pageTitleChanged = this.pageTitle$.asObservable();
    this.companyIdChanged = new EventEmitter<string>();
    this.userIdChanged = new EventEmitter<string>();
    this.userInfoChanged = new EventEmitter<UserInfoDto>();
    this.mainDepartmentChanged = new EventEmitter<UserDepartmentDto>();
    this.departmentsChanged = new EventEmitter<UserDepartmentDto[]>();
  }

  changeTitle(pageTitle: Observable<string>, windowTitle?: Observable<string>) {
    zip(...[pageTitle, windowTitle].filter(o => o)).subscribe(titles => {
      if (titles.length === 2) this.setTitle(titles[0], titles[1]);
      else this.setTitle(titles[0]);
    });
  }

  // Set page and window title. Window title will be set to pageTitle if not provided separately.
  async setTitle(pageTitle: string, windowTitle?: string) {
    const pageTitleTranslated = await this.translate.get(pageTitle).toPromise();
    const windowTitleTranslated = !!windowTitle
      ? await this.translate.get(windowTitle).toPromise()
      : pageTitleTranslated;
    this.title.setTitle(`${windowTitleTranslated} - Ecus ECOM`);
    this.pageTitle$.next(pageTitleTranslated);
  }

  setCompanyId(companyId: string) {
    this.companyId = companyId;
    this.companyIdChanged.emit(this.companyId);
  }

  setUserId(userId: string) {
    this.userId = userId;
    this.userIdChanged.emit(this.userId);
  }

  setMainDepartment(dep: UserDepartmentDto): void {
    this.mainDepartment = dep;
    this.mainDepartmentChanged.emit(this.mainDepartment);
  }

  setDepartments(dep: UserDepartmentDto[]): void {
    this.departments = dep;
    this.departmentsChanged.emit(this.departments);
  }

  setUserInfo(userInfo: UserInfoDto): void {
    this.userInfo = userInfo;
    this.userInfoChanged.emit(this.userInfo);
  }

  getCompanyId(): string {
    return this.companyId;
  }

  getUserId(): string {
    return this.userInfo.userId;
  }

  getUserInfo(): UserInfoDto {
    return this.userInfo;
  }

  getMainDepartment(): UserDepartmentDto {
    return this.mainDepartment;
  }

  getDepartments(): UserDepartmentDto[] {
    return this.departments;
  }

  getHandlerJSON(): CaseManagementUserDto {
    return {
      email: this.userInfo.email,
      name: this.userInfo.name,
      initials: this.userInfo.initials
    } as CaseManagementUserDto;
  }
}
